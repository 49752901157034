import {request,request4} from './request'
//用户注册
export function auth_sms_code(data) {
    return request4({
        url: '/auth_token/sms_code',
        method: 'post',
        data:data
    })
}
//用户注册
export function reg(data) {
    return request({
        url: '/member',
        method: 'post',
        data:data
    })
}
//短信验证码
export function sms_codes(mobile) {
    return request({
        url: '/sms_codes',
        method: 'post',
        data:{
            mobile
        }
    })
}
//登录
export function auth_token(data) {
    return request({
        url: '/auth_token',
        method: 'post',
        data:data
    })
}